@mixin respond($breakpoint) {
  //1em = 16px
  @if $breakpoint == phone-s {
    @media only screen and (max-width: 400px) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 500px) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 700px) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 750px) {
      @content;
    }
  }
  @if $breakpoint == big-desk {
    @media only screen and (min-width: 880px) {
      @content;
    }
  }
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;

  @media only screen and (max-width: 880px) {
    font-size: 80%;
  }
  @media only screen and (max-width: 750px) {
    font-size: 75%;
  }
  @media only screen and (max-width: 700px) {
    font-size: 70%;
  }
  @media only screen and (max-width: 500px) {
    font-size: 65%;
  }
  @media only screen and (max-width: 400px) {
    font-size: 60%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html * {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Montserrat", Helvetica, Arial, serif !important;
}

a,
a:link,
a:visited {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.App {
  display: flex;
  position: relative;
}

:root {
  --primary-color: #180f77;
  --primary-color-light: #2417ad;
}
